<template>
<div>

  <div class="wqhd-margin-top wqhd-margin-bottom">
    <section>
      <h2 class="text-2xl mx-auto my-4 w-8/12">プライバシーポリシー</h2>
      <div class="mx-auto my-4 p-4 w-8/12">
      当山は、本ウェブサイトをご利用していただく方に、お問合せフォームのページ等で、ご利用者の個人情報（氏名、メールアドレス、ご住所、ご連絡先等）をご提出していただく場合がございます。<br>
      ご利用者が当山に対して、ご利用者の個人情報を提出されるか否かについてのご判断は、ご利用者のご判断の元で、ご利用者に任意によりご提出していただくものとします。<br>
      </div>
    </section>

    <section>
      <h2 class="text-2xl mx-auto my-4 w-8/12">個人情報の保存および管理</h2>
      <div class="mx-auto my-4 p-4 w-8/12">
      当山は、ご利用者よりご提出していただいた個人情報を、厳重な管理体制のもとで保持し、第三者がご利用者の個人情報に不当に触れることがないように、合理的な範囲内でセキュリティの強化に努めます。
      </div>
    </section>

    <section>
      <h2 class="text-2xl mx-auto my-4 w-8/12">個人情報の利用および開示</h2>
      <div class="mx-auto my-4 p-4 w-8/12">
      ご利用者の個人情報につきましては、法令の規定に基づき、当山が司法機関または行政機関から法的義務を伴う要請を受けた場合にのみ、ご利用者の個人情報を第三者に開示することがございます。 それ以外の場合において、当山がご利用者の同意なく個人情報を第三者に開示することはございません。
      </div>
    </section>
  </div>

  <Footer class="mt-12"></Footer>

</div>
</template>


<script>

  import Footer from '../components/Footer.vue'

  export default {

    components: {
      Footer,
    },

  }
</script>


<style scoped>

@media (min-width: 2100px) { 

  .wqhd-margin-top {
    margin-top: 250px;
  }

  .wqhd-margin-bottom {
    margin-bottom: 250px;
  }

 }
</style>