<template>
<div>

  <section class="mt-12 wqhd-margin-top">
    <div class="w-10/12 mx-auto">
      <h2 class="text-2xl mx-auto my-4 w-11/12 md:w-8/12">寺報『淨心寺だより』</h2>
      <div class="mx-auto my-4 p-4 w-11/12 md:w-8/12">
        年1回8月1日に発刊しております。<br>
        檀信徒の皆様には全戸へ配布しておりますが、淨心寺HPにて公開しております。<br>
        一部掲載を削除しておりますが、ご了承下さい。
      </div>
    </div>
  </section>
  
  <section class="wqhd-margin-top wqhd-margin-bottom">
    <div class="flex justify-center mt-20 mx-auto w-10/12">
        <h2 class="text-xl lg:text-2xl leading-10  my-auto text-center h-10 w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12">淨心寺だより一覧</h2>
        <img src="../assets/PDF_32.png" alt="" class=" w-10 h-10">
    </div>

    <div class="mx-auto my-4 p-2 w-10/12">
      <div class="md:flex my-4 w-7/12 md:w-10/12 mx-auto">
        <div class="md:text-right md:pr-8 flex-grow md:w-6/12">2022.08.01</div>
        <a target="_blank" rel="noopener noreferrer" class="text-left flex-grow md:w-6/12 underline text-blue-400" href="https://firebasestorage.googleapis.com/v0/b/joushinji-web.appspot.com/o/dayori_pdf%2Fdayori-20.pdf?alt=media&token=34272c17-3642-4423-a7ac-ceab9460f73f"><div>淨心寺だより 第20号</div></a>
      </div> 
      <div class="md:flex my-4 w-7/12 md:w-10/12 mx-auto">
        <div class="md:text-right md:pr-8 flex-grow md:w-6/12">2021.08.01</div>
        <a target="_blank" rel="noopener noreferrer" class="text-left flex-grow md:w-6/12 underline text-blue-400" href="https://firebasestorage.googleapis.com/v0/b/joushinji-web.appspot.com/o/dayori_pdf%2Fdayori-19.pdf?alt=media&token=5fd48d22-9452-4246-8849-7c78c7be855b"><div>淨心寺だより 第19号</div></a>
      </div> 
      <div class="md:flex my-4 w-7/12 md:w-10/12 mx-auto">
        <div class="md:text-right md:pr-8 flex-grow md:w-6/12">2020.08.01</div>
        <a target="_blank" rel="noopener noreferrer" class="text-left flex-grow md:w-6/12 underline text-blue-400" href="https://firebasestorage.googleapis.com/v0/b/joushinji-web.appspot.com/o/dayori_pdf%2Fdayori-18.pdf?alt=media&token=8c8783c7-8e0c-4bfa-bb27-d909d78d5a8c"><div>淨心寺だより 第18号</div></a>
      </div> 
      <div class="md:flex my-4 w-7/12 md:w-10/12 mx-auto">
        <div class="md:text-right md:pr-8 flex-grow md:w-6/12">2019.08.01</div>
        <a target="_blank" rel="noopener noreferrer" class="text-left flex-grow md:w-6/12 underline text-blue-400" href="https://firebasestorage.googleapis.com/v0/b/joushinji-web.appspot.com/o/dayori_pdf%2Fdayori-17.pdf?alt=media&token=eb7589bd-5be8-4758-a16f-c8c20dd34dda"><div>淨心寺だより 第17号</div></a>
      </div> 
      <div class="md:flex my-4 w-7/12 md:w-10/12 mx-auto">
        <div class="md:text-right md:pr-8 flex-grow md:w-6/12">2018.08.01</div>
        <a target="_blank" rel="noopener noreferrer" class="text-left flex-grow md:w-6/12 underline text-blue-400" href="https://firebasestorage.googleapis.com/v0/b/joushinji-web.appspot.com/o/dayori_pdf%2Fdayori-16.pdf?alt=media&token=e1365763-469a-4ae3-a7b8-8d83221a6ea3"><div>淨心寺だより 第16号</div></a>
      </div> 
      <div class="md:flex my-4 w-7/12 md:w-10/12 mx-auto">
        <div class="md:text-right md:pr-8 flex-grow md:w-6/12">2017.08.01</div>
        <a target="_blank" rel="noopener noreferrer" class="text-left flex-grow md:w-6/12 underline text-blue-400" href="https://firebasestorage.googleapis.com/v0/b/joushinji-web.appspot.com/o/dayori_pdf%2Fdayori-15.pdf?alt=media&token=51c6ab7c-a6e3-4b1d-975b-143cdf7ce219"><div>淨心寺だより 第15号</div></a>
      </div> 
    </div>
  </section>


  <!-- フッターのコンポーネント -->
  <Footer class="mt-12"></Footer>

</div>
</template>


<script>

  import Footer from '../components/Footer.vue'

  export default {

    components: {
      Footer,
    },

    data() {
      return {
        
      }
    },

    computed: {
      
    },


    methods: {

    }, 


    watch: {

    },
  

    created() {

    },

    mounted() {

    },

  }
</script>


<style scoped>


/* WQHDディスプレイでの解像度の違いから来るmarginの調整 */
@media (min-width: 2100px) { 

  .wqhd-margin-top {
    margin-top: 150px;
  }

  .wqhd-margin-bottom {
    margin-bottom: 100px;
  }

 }

</style>