<template>
<div>

  <!-- 背景動画をfixedで固定し、その次の要素には背景色で白色を設定している -->

  <!-- 動画をfixedで常に背景へ固定表示しているのでこのdivの中に「ごあいさつ」「寺院概要」などの文字要素を入れる必要はない。fixedにすると画面固定なのでHTML構造から関係ないものになる。 -->
  <section class="top-area">
    <div class="video-area">
      <video class='bg-movie' poster="" autoplay loop muted playsinline>
        <source src="../assets/guide_movie.mp4" type="video/mp4">
      </video>
    </div>
  </section>


  <!-- ごあいさつ -->
  <section class="bg-white">
    <div class="w-11/12 md:w-8/12 mx-auto">
      <h2 class="text-2xl mx-auto pt-8 w-10/12">ごあいさつ</h2>

      <div class="mx-auto p-4 w-9/12">
        聖号十念称<br>

        平成17年より始めた淨心寺ホームページは、今回で3度目のリニューアルとなります。<br>
        ホームページを始めた切っ掛けは、檀家様にお寺のこと住職のことをもっと知って頂くため、お寺の敷居が高いイメージを払拭し、お寺を身近に感じて頂き、いつでも気軽にお参りできるお寺に出来ればと思ったからです。<br>
        平成9年5月15日、前住職村上隆澄和尚が遷化し、その後住職を引き継ぎ、いろいろなことを試みました。<br>
        平成14年5月12日に、淨心寺本堂落慶式並びに晋山式を執り行って以来、正式に住職となってからは定例行事の見直し、彼岸中日別時念佛会、寺報『淨心寺だより』発刊、団体参拝、法話・別時念佛会等々を行いました。<br>
        それから境内整備と致しまして、無縁塔並びに集合墓の建立、広大な庭園を造園し、春はシャクナゲ、秋は紅葉の見応えのある境内に造り変えました。<br>
        今後ともホームページを通じて、いろいろな形のお寺をお知らせし、淨心寺が檀家様の心の拠り所となるよう精進して参りたい所存です。<br>
        ホームページのリニューアルに当たりまして、まずは御挨拶とさせて頂きます。<br>

      </div>
      <div class="mx-auto pr-4 w-9/12 text-right">
        合 掌
      </div>
      <div class="mx-auto pr-4 w-9/12 text-right">
        淨心寺廿三世 荒木敏企
      </div>
    </div>
  </section>


  <!-- 寺院概要 -->
  <!-- 阿弥陀様画像を重ねるために「relative」を使用 -->
  <section class="pt-8 bg-white relative">
    <div class="w-11/12 md:w-8/12 mx-auto">

      <h2 class="text-2xl mx-auto pt-8 w-10/12">寺院概要</h2>

      <div class="flex justify-center">

        <!-- 阿弥陀様画像重ねる -->
        <div class="absolute top-60 md:top-10 left-20 opacity-20 md:opacity-30">
          <img src="../assets/amida_illust.png" alt="" class="w-5/6 md:w-3/6">
        </div>

        <!-- 寺院概要の文字要素ブロック -->
        <div class="w-7/12 md:w-5/12 text-center">
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">寺名</div>
            <div class="flex-grow md:w-8/12">一佛山光明院淨心寺</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">宗旨</div>
            <div class="flex-grow md:w-8/12">浄土宗</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">所属</div>
            <div class="flex-grow md:w-8/12">総本山知恩院末寺</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">創建</div>
            <div class="flex-grow md:w-8/12">天正6年（1578年）</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">開山</div>
            <div class="flex-grow md:w-8/12">存蓮社唯称泉譽上人</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">開基</div>
            <div class="flex-grow md:w-8/12">光明院殿一譽脱叟淨心大居士<br>小笠原但馬守貞政</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">中興</div>
            <div class="flex-grow md:w-8/12">第十三世 明譽上人<br>第廿世 締譽上人</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">住職</div>
            <div class="flex-grow md:w-8/12">第廿三世 荒木 敏企</div>
          </div>
          <div class="md:flex my-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">所在地</div>
            <div class="flex-grow md:w-8/12">〒399-8103<br>長野県安曇野市三郷小倉3360</div>
          </div>
          <div class="md:flex mt-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">電話</div>
            <div class="flex-grow md:w-8/12">0263-77-2595</div>
          </div>
          <div class="md:flex mt-4 md:w-9/12 mx-auto">
            <div class="flex-grow md:w-1/12">FAX</div>
            <div class="flex-grow md:w-8/12">0263-77-3880</div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- 淨心寺の沿革 -->
  <section class="pt-8 bg-white">
    <div class="w-11/12 md:w-8/12 mx-auto">

      <h2 class="text-2xl mx-auto pt-8 w-10/12">淨心寺の沿革</h2>

      <div class="mx-auto p-4 w-9/12">
        当寺は、一佛山光明院淨心寺と称し浄土宗総本山知恩院の末寺である。小倉城主小笠原但馬守貞政（おがさわらたじまのもりさだまさ）の草創とされ、 天正6年（1578）北小倉鳴沢入りの円山に創立された。開山は専念寺（豊科町真々部）の第五世泉譽上人（せんよしょうにん）といわれる。<br>
        第十三世明譽上人（みょうよしょうにん）の天明年間に、山火事により寺を悉く（ことごとく）消失し、北小倉中村の裏山（鎮守山）に境内を移し、同6年（1786）に再興された。このとき、本堂・庫裏・山門等を建立した。境内地には霊聖院（れいしょういん）・真立軒を擁した。なお、梓川村北大妻の唯称院（ゆいしょういん）、南大妻の光入寺（こうにゅうじ）、岩岡の音声寺（おんしょうじ）、三郷村住吉の善福寺（ぜんぷくじ）等の末寺があった。<br>
        明治初年に松本藩のとった廃仏毀釈政策により廃寺となり、同6年からは小倉学校として使用された。<br>
        同18年（1885）に至って、第廿世上嶋貞巌和尚（かみじまていがんかしょう・同34年遷化）により現在地に再興された。庫裏がまず新築され、その後同23年頃に、旧本堂を再建した。<br>
        大正年間第廿一世村上徳源和尚（むらかみとくげんかしょう）により、八間四面の本堂の改築を予定していたが、経済不況の為中止となった。<br>
        第廿二世村上隆澄和尚（むらかみりゅうちょうかしょう）は、老朽化した庫裏を昭和57年に改築し、平成8年（1996）本堂の改築を発願した後、同9年5月15日志半ばで遷化された。後を継いだ現住職により、同12年（2000）新本堂が完成の運びとなった。<br>
      </div>
    </div>
  </section>


  <!-- フッターのコンポーネント -->
  <!-- 背景動画が見えてしまうので背景色に白色を設定した -->
  <section class="pt-12 bg-white">
    <Footer class=""></Footer>
  </section>

</div>
</template>


<script>

  import Footer from '../components/Footer.vue'

  export default {
    components: {
      Footer,
    },

  }

</script>


<style scoped>

.top-area {
  position: relative;
  /* ここで高さしか指定しないのがポイント。しかしVue.jsではうまくいかない。 */
  /* 厳密にはうまくサイズは変更するが動画のサイズ指定だけCSSが効かない */
  /* その後の調査で「object-fit」を使用すると効くようになる。これがあるないが純粋HTML、CSSとの違い。Vue.js独自の仕様。 */
  /* 別コンポーネントから読み込むフッター要素が上に配置されてしまう問題はここで高さを指定するおかげで解決できた */
  /* 「top-area」は全体のコンポーネントを囲っている。ここに高さを指定するのがポイント */
  /* 高さがあるから壁になってフッターは上に配置されることはなくなった */
  /* 単純なようだが気が付かないとハマる */
  /* このことから背景動画や文字を重ねるなど複雑なレイアウトでWebページを構成する時は */
  /* HTML全体をクラスで囲って高さしていしたり重ね順を変更することにより作成する */
  /* そうすると複雑なレイアウトが作成しやすくなると思われる */


  /* もしトップで背景動画だけを表示したい場合はここで高さ指定が必要 */
  /* ここのheightを変更すると動画表示領域が大きくなる */
  /* height: 100vh; */

  /* 現在は80vhくらいが丁度いい高さだと判断した */
  height: 80vh;
  /* height: 75vh; */
  /* height: 8vh; */

  /* height: 110vh; */
  /* height: 150vh; */

  /* background-color: rgb(221, 163, 163); */
}


.video-area {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* width: 100vw; */
  height: 100vh;
  /* height: 80vh; */

  overflow: hidden;
  /* background-color: rgb(239, 239, 127); */
}


/* YouTube解説動画のクラス「video」の同じになるようにした */
.bg-movie {
  position: absolute;
  z-index: -1;

  /* object-fitを使用するとvideoのソースにある動画の縦横比のCSS設定が効くようになる */
  /* object-fit: fill; */
  object-fit: cover;
  /* object-fit: contain; */

  /* この辺のtopなどの位置調整は必要なのか？ */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  width: 100%;
  height: 100%;

  min-width: 100%;
  min-height: 100%;

  /* background-color: rgb(123, 251, 189); */
}
</style>