<template>

<header class="text-lg text-gray-700 font-semibold">

  <!-- スマホサイズ用のハンバーガーメニューのボタン -->
  <div class="md:hidden fixed top-10 right-4 z-50">
    <div class="btn-hamburger" v-on:click="openHamburger()">
      <img v-if="!hamburgerButton" src="../assets/hamberger_01_02.png" alt="">
      <img v-else src="../assets/hamberger_sm_close.png" alt="">
    </div>
  </div>


  <!-- タブレットサイズ以上用のハンバーガーメニューのボタン。クリックした時の関数もメニュー同様に別物になっている。 -->
  <div v-show="scrollY > 200" class="hidden md:block fixed top-14 right-4 z-50">
    <div class="btn-hamburger-tablet mr-4" v-on:click="openHamburgerTablet()">
      <img v-if="!hamburgerButtonTablet" src="../assets/hamberger_01_02.png" alt="">
      <img v-else src="../assets/hamberger_02_02.png" alt="">
    </div>
  </div>

  <!-- スマホメニュー用の全体を覆う背景色 -->
  <div :class="backwindow"></div>

  <!-- スマホサイズ用のメニュー。スマホサイズ用ではスクロールに関係なく常にハンバーガーメニューが表示されて縦並びでメニュー項目が表示される。 -->
  <!-- バインドクラス用の変数名は「smart」+「タグ名」にした。 -->
  <nav class="md:hidden w-full mx-auto" :class="smartNav">
    <ul class="menu-color">
      <li class="text-center text-white py-6">
        <router-link to="/">ホーム</router-link>
      </li>
      <li class="text-center text-white py-6">
        <router-link to="/about">淨心寺について</router-link>
      </li>
      <li class="text-center text-white py-6">
        <router-link to="/guide">境内案内</router-link>
      </li>
      <li class="text-center text-white py-6">          
        <router-link to="/jihou">淨心寺だより</router-link>
      </li>
      <li class="text-center text-white py-6">
        <a href="http://joushinji.jugem.jp">住職日記</a>
      </li>
      <li class="text-center text-white py-6">
        <!-- 「お知らせ」「アクセス」にはアンカーを設定した。別ページからでもアンカー場所へ飛べる。 -->
        <router-link to="/#informationBlock">お知らせ</router-link>
      </li>
      <li class="text-center text-white py-6">
        <router-link to="/#accessBlock">アクセス</router-link>
      </li>
    </ul>
  </nav>


  <!-- タブレットサイズ以上用のメニュー。スクロールするとハンバーガーメニュー用に縦並びになる。 -->
  <!-- バインドクラス用の変数名は「tablet」+「タグ名」にした。 -->
  <nav class="hidden md:block mx-auto" :class="tabletNav">
    <ul class="justify-end" :class="tabletUl">
      <li v-show="scrollY >= 200" class="text-center" :class="tabletLi">
        <router-link to="/">ホーム</router-link>
      </li>
      <li class="text-center" :class="tabletLi">
        <router-link to="/about">淨心寺について</router-link>
      </li>
      <li class="text-center" :class="tabletLi">
        <router-link to="/guide">境内案内</router-link>
      </li>
      <li class="text-center" :class="tabletLi">          
        <router-link to="/jihou">淨心寺だより</router-link>
      </li>
      <li class="text-center" :class="tabletLi">
        <a href="http://joushinji.jugem.jp">住職日記</a>
      </li>
      <li v-show="scrollY >= 200" class="text-center" :class="tabletLi">
        <router-link to="/#informationBlock">お知らせ</router-link>
      </li>
      <li v-show="scrollY >= 200" class="text-center" :class="tabletLi">
        <router-link to="/#accessBlock">アクセス</router-link>
      </li>
    </ul>
  </nav>


</header>


</template>


<script>
  export default {
    data() {
      return {

        // 縦スクロール量
        scrollY: 0,

        // ウィンドウ幅によって処理を変える時に必要な以前のウィンドウ幅
        preWidth: '',

        // ハンバーガーメニューボタンのONOFF。ボタンがONOFFになるだけのフラグ。
        hamburgerButton: false,
        hamburgerButtonTablet: false,

        // 初期値を「hidden」にしてメニュー全体を隠している。「hidden」とはtailwindのCSSクラスdisplay:none;するだけ
        smartNav: 'hidden',
        // タブレットサイズ以上で表示するメニュー用変数
        tabletNav: '',
        tabletUl: 'flex',
        // 各メニュー項目。初期値は「md:w-48」で適切な大きさ。スクロールしてタブレットサイズ以上ハンバーガーメニューでは「w-full」になる。
        tabletLi: 'md:w-48',
        // スマホメニューの背景で画面全部を覆う要素
        backwindow: '',
        // 「$route」オブジェクトを入れるだけ
        routeObj: []

      }
    },


    methods: {

      getScroll() {
        this.scrollY = window.scrollY
        // デバッグ作業用にスクロール量をコンソールへ出力している
        // console.log(this.scrollY);

        // 縦スクロール量300以下になったらタブレットサイズ以上のメニューを横並びにする
        if(this.scrollY <= 300) {
          this.hamburgerButtonTablet = false
          this.tabletUl = 'flex'
          this.tabletNav = ''
          this.tabletLi = 'md:w-48'
        } 
      },


      // スマホサイズで表示されるハンバーガーメニューボタンをクリックした時
      openHamburger() {

        // console.log('openHamburger発火');

        // スイッチを切り替えるイメージ。trueならfalseになってfalseならtrueになる。
        // この値を元に次のif文でハンバーガーメニューの開閉処理を分ける
        this.hamburgerButton = !this.hamburgerButton

        // ハンバーガーボタンを開く時の処理
        if(this.hamburgerButton === true) {

          // console.log('変数hamburgerButtonがtrueの時の処理が始まる');

          // ハンバーガーメニューを開いてクラスを付与する
          // 「fade-right」が右へ要素が移動するアニメCSSクラス。
          this.smartNav = 'fade-right smart-nav'
          this.backwindow = 'fade-right w-full menu-color fixed h-full'

        // ハンバーガーボタンを閉じる時の処理
        } else {

          // console.log('変数hamburgerButtonのfalse時の処理が始まる');
          // ハンバーガーメニューを閉じてクラスをリセットする。
          this.smartNav = 'invisible smart-nav'
          this.backwindow = 'invisible'

        }
      },


      // タブレットサイズ以上で表示されるハンバーガーメニューボタンをクリックした時
      openHamburgerTablet() {

        // console.log('openHamburgerTablet発火');

        // スイッチを切り替えるイメージ。trueならfalseになってfalseならtrueになる。
        // この値を元に次のif文でハンバーガーメニューの開閉処理を分ける
        this.hamburgerButtonTablet = !this.hamburgerButtonTablet

        // ハンバーガーボタンを開く時の処理
        if(this.hamburgerButtonTablet === true) {

          // console.log('変数hamburgerButtonTabletのtrue時の処理が始まる');

          // ハンバーガーメニューを開いてクラスを付与する
          // 「fade-right」が右へ要素が移動するアニメCSSクラス。
          // タブレットサイズ以上のメニュー場合「fixed」がないとハンバーガーメニューになる前のメニュー(ページ上部にある)が非表示なるためスクロールしいているとメニューの非表示自体が見えない

          this.tabletNav = 'fade-right fixed top-20 left-0 justify-center w-9/12 lg:w-10/12'
          this.tabletUl = 'opacity-90 menu-color menu-radius h-3/5 p-10'
          this.tabletLi = 'w-full py-1 text-white text-xl pb-4'

        // ハンバーガーボタンを閉じる時の処理
        } else {

          // console.log('変数hamburgerButtonTabletのfalse時の処理が始まる');

          // ハンバーガーメニューを閉じてクラスをリセットする。
          this.tabletNav = 'invisible fixed top-20 left-0 justify-center w-9/12 lg:w-10/12'
          this.tabletUl = 'opacity-90 menu-color menu-radius h-3/5 p-10'
          this.tabletLi = 'w-full py-1 text-white text-xl pb-4'

        }
      },


      // ウィンドウ幅が一定になった時に発火する関数
      windowResize() {

        // タブレットサイズ以上の用メニューを開いたらウインドウ幅768px以下になるまで開いたまま、スマホ用メニューを開いたら768px以上になるまで開いたままの処理をしている。
        // つまり、ウインドウ幅によるメニューの初期化処理を書いている
        // 本来なら768pxピッタリで処理をして欲しかった。しかしウィンドウ幅をシームレスに取得出来ないので、
        // 「preWidth」という変数に以前のウィンドウ幅を保持してそれを基準にif文で分けることでウインドウ幅が768pxを境に処理ができる
        // if文の中にif文で4通り(一部はさらにif文がある)の処理を書いて実現している


        // ウィンドウ幅768px以上での処理。タブレットサイズ以上の状態でウィンドウ幅を変更してもメニューを開いたままにするため
        if (window.innerWidth >= 768) {

          // preWidthを条件にすることがポイント。768px以上でサブメニューを開いてウインドウ幅を可変しても何も処理しないでメニューは開いたまま
          if (this.preWidth >= 768) {
            // console.log('preWidthでの処理しない');
          } else {

            // console.log('ウィンドウ幅が小から大になった');
            // ここでpreWidthを書き換えることがポイント
            // 800pxは適当な数字、768以上なら何でもいい。本来はtrue or falseで処理した方がいいが分かりやすさ重視で特定の数字値を入れた。
            this.preWidth = 800

            // ハンバーガーメニューボタンをOFFにする
            this.hamburgerButton = false

            // スマートフォン用メニューを非表示にする
            this.smartNav = 'hidden'
            this.backwindow = 'invisible'

            // タブレットサイズ以上のメニューを初期化する処理
            if(this.scrollY >= 300) {
              this.hamburgerButtonTablet = false
              // hiddenではなくopacity-0で消している。その後opacity-0では透明だがクリック出来てしまうので問題判明。「invisible」で要素を消している
              // 以下2つは失敗例。要素が消えていないからクリック出来てしまう
              // this.tabletNav = 'fixed top-20 left-0 bg-red-600 justify-center w-6/12'
              // this.tabletNav = 'fixed top-20 left-0 bg-red-600 justify-center w-6/12 opacity-0'
              // 「bg-red-600」は要素を見やすくするため開発用なので後で消す。「invisible」つまりCSSのvisibility: hidden;で消している
              //  そもそもdisplay: none;がfixedと被るからvisibility使用した。メニュー自体消すならfixedを無くしてhiddenにする方法もあり
              this.tabletNav = 'fixed top-20 left-0 justify-center w-9/12 invisible'
              this.tabletUl = ''
              this.tabletLi = 'w-full'
            } else {
              this.hamburgerButtonTablet = false
              this.tabletNav = ''
              this.tabletUl = 'flex'
              this.tabletLi = 'md:w-48'
            }

          }

        // スマートフォンサイズの時の処理 基本的に上記if文の反対の動作をしている。
        } else {

          if (this.preWidth < 768) {
            // console.log('preWidthでの処理しない');
          } else {

            // console.log('ウィンドウ幅が大から小になった');

            // ここでpreWidthを書き換えることがポイント
            // 500pxは適当な数字、768以下なら何でもいい
            this.preWidth = 500

            // メニューボタンを非表示
            this.hamburgerButton = false
            this.hamburgerButtonTablet = false

            // スマートフォン用メニューを非表示にする
            this.smartNav = 'hidden'
            this.backwindow = 'invisible'
          }
        }
      }

    }, //methods終わりのカッコ。消さないように。


    computed: {
      
    },//computed終わりのカッコ。


    watch: {

      // ページ遷移時に開いていたメニューを閉じる
      $route(to) {
        // 「to」を使用しないとエラーになってしまうので入れている
        // consoleにも表示したくないので「routeObj」へtoを代入するだけの処理
        this.routeObj = to
        // console.log(to);

        if(this.hamburgerButton == true || this.hamburgerButtonTablet == true) {

          // console.log('メニュー遷移してif文の中に来たよ');

          // スマホサイズ、タブレットサイズ以上どちらのメニューも開いた状態でページ遷移したら閉じるようにした
          // アニメーションで閉じるのではなく一瞬で消えるようになっている。こうしないとタブレットサイズ以上でスクロールなしで表示される横並びメニューが消えてしまうから。
          this.smartNav = 'hidden'
          this.tabletUl = 'flex'
          this.tabletNav = ''
          this.tabletLi = 'md:w-48'
          this.hamburgerButton =  false
          this.hamburgerButtonTablet = false
          this.backwindow = 'invisible'
        }
      }


    },//watch終わりのカッコ。
  

    // createdとmountedも似ているが違うもの。ライフサイクルではcreatedが先。
    created() {

      // まずはページが表示された時に自分で定義したウィンドウ幅をきっかけに発火する関数を実行
      this.windowResize()
      // ウィンドウオブジェクトが変化する時に自分で定義したメソッドを発火
      // 気になる点としてウィンドウ幅が変わるたびに毎回メソッドが実行されるけどパフォーマンス的に大丈夫なのだろうか？
      window.addEventListener('resize', this.windowResize)

      // ページ開始時のwidthを取得
      this.preWidth = window.innerWidth
  
    },//created終わりのカッコ。

    mounted() {
      window.addEventListener("scroll", this.getScroll)
    },//mounted終わりのカッコ。

    destroyed() {
      //「created」は基本一度しかイベントが発生しないので「destroyed」でイベントを削除してウィンドウ幅が可変する度にメソッドが発火する
      window.removeEventListener('resize', this.windowResize)
    }

  }// export defaultの終わりのカッコ。

</script>


<style scoped>

/* ------------------------------- */
/* ハンバーガーボタン */
/* ------------------------------- */

/* 現在はハンバーガーボタンのCSSはこれしか設定しない。設定が増えないなら最終的にtailwindだけにした方がいい。 */

/* スマホサイズのハンバーガーボタン */
.btn-hamburger {
  /* ハンバーガーボタンのサイズ */
  width: 60px;
  cursor: pointer;
}

/* タブレットサイズ以上のハンバーガーボタン */
.btn-hamburger-tablet {
  width: 75px;
  cursor: pointer;
}


/* --------------------------------------------------------------------------------------------- */

/* ------------------------------- */
/* メニュー */
/* ------------------------------- */

/* スマートフォンサイズの時にメニュー全体の要素を絶対値表示するため */
.smart-nav {
  position: fixed;
  z-index: 49;
  top: 50px;
  left: 0;
}

/* ハンバーガーメニューの角丸設定。tailwindではここまで丸くすることは出来なかったので自作した。 */
.menu-radius {
  border-radius: 0 40px 0 0;
}

/* メニュー全体の色。フッターと同じ色にしている。 */
.menu-color {
  background-color: #02033F;
}

/* --------------------------------------------------------------------------------------------- */

/* ------------------------------- */
/* メニューアニメーション */
/* ------------------------------- */


/* 現在は「fade-right」は使用している。「fade-left」使用していない。 */
/* メニューのアニメーションを設定はCSSだけで行った */

.fade-left {
  animation-name: fadeLeftAnime;/*アニメーションの定義名*/
  animation-duration:1s;/*アニメーション変化時間 */
  animation-fill-mode:forwards;/*アニメーションの開始と終了時の状態を指定*/
  /* opacity:0; */
}

/* 変数「backwindow」用に作成した */
/* しかしその後メニューを消す場合にopacityが設定されているとうまく消えないのでメニューを消す時はアニメ自体を使用しなくなった */
.fade-left-window {
  animation-name: fadeLeftWindowAnime;
  animation-duration:1s;
  animation-fill-mode:forwards;
  /* opacity:0; */
}

.fade-right {
  animation-name: fadeRightAnime;
  animation-duration:1s;
  animation-fill-mode:forwards;
  /* opacity:0; */
}


/* ------------------------------- */
/* メニューアニメーション用キーフレーム */
/* ------------------------------- */

/*アニメーションの開始から終了までを指定する*/

@keyframes fadeLeftAnime{
  from {
    transform: translateX(0);
    /* opacity: 1; */
  }

  to {
    transform: translateX(-30px);
    /* 「opacity: 0;」だと透明になるだけで隠れない。メニューがクリックできてしまう */
    /* 要素を隠す */
    visibility:hidden;
  }
}


@keyframes fadeLeftWindowAnime{
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-30px);
    visibility:hidden;
  }
}


@keyframes fadeRightAnime{
  from {
    /* opacity: 0; */
    transform: translateX(-30px);
  }

  to {
    /* opacity: 1; */
    transform: translateX(0);
  }
}


</style>