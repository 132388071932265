<template>

  <footer class="footer-color footer-radius text-white text-sm py-10 md:w-5/6 md:flex">

    <!-- iPhone10系のノッチあり端末で画面いっぱいに表示すると文字が被るので左にmarginを取った -->
    <div class="md:w-5/12 md:ml-11">
      <!-- 「淨心寺」の手書きロゴを中心に配置するために元画像を2つの画像に分割した。 -->
      <!-- 現在、横幅は全画面サイズで固定値にしている。 -->
      <img src="../assets/joushinji_logo_white_separate_first.png" alt="" class="mx-auto w-60">
      <img src="../assets/joushinji_logo_white_separate_second.png" alt="" class="mx-auto w-24">
      <div class="mt-4 text-center text-sm">浄土宗 淨心寺 長野県安曇野市三郷小倉3360</div>
    </div>

    <div class="mt-2 text-center md:text-left md:w-3/12 xl:w-2/12 text-base md:text-lg md:ml-10">
      <div class="mb-3">
        <router-link to="/">ホーム</router-link>
      </div>
      <div class="mb-3">
        <router-link to="/about">淨心寺について</router-link>
        </div>
      <div class="mb-3">
        <router-link to="/guide">境内案内</router-link>
      </div>
      <div class="mb-3">
        <router-link to="/jihou">淨心寺だより</router-link>
      </div>
      <div class="mb-3">
        <a href="http://joushinji.jugem.jp">住職日記</a>
      </div>
    </div>

    <!-- 右に隙間を空けたかったのでw-4/12にした -->
    <div class="mt-2 text-center md:text-left md:w-4/12 text-base md:text-lg">
      <div class="mb-3">
        <router-link to="/#informationBlock">お知らせ</router-link>
      </div>
      <div class="mb-3">
        <router-link to="/#accessBlock">アクセス</router-link>
      </div>
      <div class="mb-3">
        <!-- メールリンク設定 -->
        <a href="mailto:itibutuzan@joushinji.com">お問い合わせ</a>
      </div>
      <div class="mb-3">
        <router-link to="/privacypolicy">プライバシーポリシー</router-link>
      </div>
    </div>

  </footer>

</template>


<script>
  export default {

    data() {
      return {
      }
    },

    computed: {
      
    },
    
    methods: {

    }, 

    watch: {

    },
  

    created() {

    },

    mounted() {

    },

  }
</script>


<style scoped>

/* フッターのカラー */
.footer-color {
  background-color: #02033F;
}

/* 角丸 */
.footer-radius {
  border-radius: 0 40px 0 0;
}

/* スマートフォン表示用のメディアクエリ */
@media screen and (max-width:767px) {

  .footer-radius {
    border-radius: 0 0 0 0;
  }

}

</style>