<template>
<div>

  <section class="text-gray-600">
    <div class="container mx-auto flex px-5 py-12 md:py-20 md:flex-row flex-col items-center md:items-start">
      <div class="md:w-5/12 w-5/6 mb-10 md:mb-0">
        <img class="object-cover object-center rounded-xl shadow-2xl" alt="" src="../assets/hondou_001.jpg">
      </div>
      <div class="lg:flex-grow md:w-1/2 w-10/12 lg:pl-24 md:pl-16 flex flex-col md:items-start text-left">
        <h1 class="sm:text-4xl text-3xl mb-4 font-medium text-gray-900 text-center">本堂</h1>
        <p class="text-lg leading-relaxed">大きさ:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">間口八間、奥行十間、裏堂二間 本堂、裏堂合わせて百坪</p>
        <p class="text-lg leading-relaxed">形:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">入母屋妻入造、三間流れ向拝付</p>
        <p class="text-lg leading-relaxed">屋根:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">銅板平葺</p>
        <p class="text-lg leading-relaxed">材料:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">柱は木曽檜、その他は米檜葉、米松を使用</p>
        <p class="text-lg leading-relaxed">参考本堂:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">知恩院御影堂、知恩院勢至堂、善光寺大本願  本堂、滋賀県西明寺</p>
      </div>
    </div>
  </section>


  <section class="text-gray-600">
    <div class="container mx-auto flex px-5 py-12 md:py-20 flex-col items-center md:items-start md:flex-row-reverse">
      <div class="md:w-5/12 w-5/6 mb-10 md:mb-0">
        <img class="object-cover object-center rounded-xl shadow-2xl" alt="" src="../assets/inaridou.jpg">
      </div>
      <div class="lg:flex-grow md:w-1/2 w-10/12 lg:pr-24 md:pr-16 flex flex-col md:items-start text-left">
        <h1 class="sm:text-4xl text-3xl mb-4 font-medium text-gray-900 text-center">愛染稲荷堂</h1>
        <p class="text-lg leading-relaxed">大きさ:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">間口二尺二寸、奥行一尺八寸</p>
        <p class="text-lg leading-relaxed">形:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">一間社流れ造</p>
        <p class="text-lg leading-relaxed">屋根:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">銅板平葺</p>
        <p class="text-lg leading-relaxed">材料:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">檜</p>
        <p class="text-lg leading-relaxed">参考社殿:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">下賀茂神社、宇治上神社</p>
      </div>
    </div>
  </section>


  <section class="text-gray-600">
    <div class="container mx-auto flex px-5 py-12 md:py-20 md:flex-row flex-col items-center md:items-start">
      <div class="md:w-5/12 w-5/6 mb-10 md:mb-0">
        <img class="object-cover object-center rounded-xl shadow-2xl" alt="" src="../assets/kannondou.jpg">
      </div>
      <div class="lg:flex-grow md:w-1/2 w-10/12 lg:pl-24 md:pl-16 flex flex-col md:items-start text-left">
        <h1 class="sm:text-4xl text-3xl mb-4 font-medium text-gray-900 text-center">観音堂（旧泉光寺聖観世音菩薩御本尊）</h1>
        <p class="text-lg leading-relaxed">大きさ:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">間口十尺八寸、奥行十九尺二寸</p>
        <p class="text-lg leading-relaxed">形:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">寄棟造</p>
        <p class="text-lg leading-relaxed">屋根:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">銅板平葺</p>
        <p class="text-lg leading-relaxed">材料:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">檜、米松</p>
        <p class="text-lg leading-relaxed">参考建築物:</p>
        <p class="mb-2 ml-4 text-lg leading-relaxed">銀閣寺東求堂</p>
      </div>
    </div>
  </section>


  <section class="text-gray-600">
    <div class="container mx-auto flex px-5 py-12 md:py-20 flex-col items-center md:items-start md:flex-row-reverse">
      <div class="md:w-5/12 w-5/6 mb-10 md:mb-0">
        <img class="object-cover object-center rounded-xl shadow-2xl" alt="" src="../assets/hondou_002.jpg">
      </div>
      <div class="lg:flex-grow md:w-1/2 w-10/12 lg:pr-24 md:pr-16 flex flex-col md:items-start text-left">
        <h1 class="sm:text-4xl text-3xl mb-4 font-medium text-gray-900 text-center">本堂全体</h1>
        <p class="mb-8 leading-relaxed">淨心寺の本堂は、境内地の関係上縦長の建造物となり、妻入りという工法で造られた。<br>
        妻入りの本堂は少ないが、各法要の際の使い勝手は大変便利である。<br>
        本堂外観は屋根が綺麗な勾配をしているので、山の中にあるお寺として自然と調和が取れた建物である。</p>
      </div>
    </div>
  </section>


  <section class="text-gray-600">
    <div class="container mx-auto flex px-5 py-12 md:py-20 md:flex-row flex-col items-center md:items-start">
      <div class="md:w-5/12 w-5/6 mb-10 md:mb-0">
        <img class="object-cover object-center rounded-xl shadow-2xl" alt="" src="../assets/butsuzo.jpg">
      </div>
      <div class="lg:flex-grow md:w-1/2 w-10/12 lg:pl-24 md:pl-16 flex flex-col md:items-start text-left">
        <h1 class="sm:text-4xl text-3xl mb-4 font-medium text-gray-900 text-center">本尊阿弥陀如来</h1>
        <p class="mb-4 leading-relaxed">阿弥陀如来 総丈 159㎝（座像）</p>
        <p class="mb-4 leading-relaxed">台座の底に左記の銘がある。</p>
        <p class="mb-4 leading-relaxed">天正十四戌年（1586）建立之凡二百年也泉譽代<br>天明六年（1786）本尊観世音勢至地蔵内佛善導<br>圓光今上牌大佛師庭正作 明譽代再興<br></p>
        <p class="mb-4 leading-relaxed">明治初年に廃佛毀釈の難に遭い、廿世上嶋貞巌和尚（当時19歳）は、本尊佛、開山上人像など主な佛像を梓川村下角影の生家へ預けた。<br>明治18年に当寺が再興され、預けた佛像を迎え入れる。<br>平成14年本堂改築に伴い、京都で破損部分の修復と光背を新調する。<br>奈良県興福寺北円堂の本尊、弥勒如来の光背を参考にし、2年がかりで現在の後背（飛天後背）が完成した。</p>
      </div>
    </div>
  </section>


  <!-- フッターのコンポーネント -->
  <Footer class="mt-12"></Footer>

</div>
</template>


<script>

  import Footer from '../components/Footer.vue'

  export default {

    components: {
      Footer,
    },

    data() {
      return {

      }
    },

    computed: {
      
    },

    methods: {

    }, 

    watch: {

    },
  
    created() {
      
    },

    mounted() {

    },

  }
</script>


<style scoped>

</style>