<template>
<div>

<section class="wqhd-margin-top">

  <!-- スキップボタンとスライドショー画像を横並びにしている。タブレットサイズ以上の時だけスライドショー画像の横にスキップボタンが見える。 -->
  <div class="img-top-area">

    <!-- タブレットサイズ以上用のスライドショー画像スキップボタン スライドショー画像の横へ配置するためにこの場所に書いた -->
    <!-- 矢印imgとその親divでCSS分けているけどすべて親divに付与でいい気がする -->
    <div class="hidden md:flex btn-skip-tablet">
      <img src="../assets/skipArrow.png" alt="" v-on:click="skipScroll" class="cursor-pointer mb-32 mx-auto w-6/12 xl:w-4/12">
    </div>


    <!-- スライドショー画像の領域 -->
    <div class="video-area bg-depth w-8/12">

      <!-- 自作スライドショー v-for使用したver-->
      <!-- v-forでimg要素を生成する方法でないとDOM操作が正常動作しない。JSだけでは無理だったので最終的にこの書き方になった。 -->
      <!-- safariでスライドショー画像が半分しか読み込まれずに動作する問題を解決した。なぜか「bg-white」などの背景色を付けると解決する。 -->
      <img v-for="img in imgArray" :key="img" :src="img" class="bg-movie slide-img bg-anime-zoom bg-white">

    </div>
  </div>


  <!-- スマホサイズ用のスキップボタン -->
  <!-- この位置にタグを書くのがポイント。ここからtranslate()で上にズラすことでスライドショー画像の上に重ねることが可能になる。 -->
  <div class="md:hidden mx-auto btn-skip">
    <img src="../assets/skipArrow.png" alt="" v-on:click="skipScroll" class="cursor-pointer">
  </div>


  <!-- お知らせ -->
  <!-- 「id」を設定してアンカーポイントにしている。他ページからでもここへ飛べるようになっている。 -->
  <section id="informationBlock" class="information-section w-full mt-6 md:mt-12" ref="skipScrollTarget">

    <h2 class="text-2xl mx-auto my-2 md:my-4 w-11/12">お知らせ</h2>

    <!-- v-forの場合非同期でdetaが初期に無くてもエラーにならない -->
    <div v-for="(list,index) in blogRss" :key="index" class="mx-auto w-10/12 mb-1 mt-2">
      <div class="flex">
        <div class="w-5/12 md:w-3/12 lg:w-2/12">{{ list.pubDate}}</div>
        <!-- カテゴリーが無い場合以下は表示しない -->
        <div v-if="list.categories.length" class="w-7/12 md:w-9/12 lg:w-10/12">【{{ list.categories[0]}}】</div>
      </div>

      <!-- ブログへのリンク範囲が下線がある部分すべて -->
      <a :href="list.link">
        <div class="py-2 border-b border-gray-400 text-lg">
          {{ list.title }}
        </div>
      </a>
    </div>

  </section>

  <!-- アクセス -->
  <!-- 「id」を設定してアンカーポイントにしている。他ページからでもここへ飛べるようになっている。 -->
  <section id="accessBlock" class="mt-12">
    <h2 class="text-2xl mx-auto my-2 md:my-4 w-11/12">アクセス</h2>

    <div class="mx-auto w-10/12 mb-4">〒399-8103  長野県安曇野市三郷小倉3360</div>

    <div class="flex mx-auto w-10/12 mb-1 pb-2 border-b border-gray-400">
      <div class="w-3/12 md:w-2/12 2xl:w-1/12 font-extrabold text-sm">松本駅から<br>お越しの場合</div>
      <div class="w-9/12 md:w-10/12 2xl:w-11/12 ml-4">松本駅アルプス口よりタクシーで27分。</div>
    </div>

    <div class="flex mx-auto w-10/12 mb-1 pb-2">
      <div class="w-3/12 md:w-2/12 2xl:w-1/12 font-extrabold text-sm">お車で<br>お越しの場合</div>
      <div class="w-9/12 md:w-10/12 2xl:w-11/12 ml-4">
        <div class="pb-2 border-b border-gray-400">東京方面より 長野自動車道「梓川SAスマートIC（下り）」より約18分（9.9Km）</div>
        <div class="">長野方面より 長野自動車道「梓川SAスマートIC（上り）」より約20分（10.9Km）</div>
      </div>
    </div>

    <!-- Googleマップ -->
    <div class="mx-auto w-10/12 mt-12">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8344.217738691901!2d137.83541070481255!3d36.26048030104474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601d14aa5c0660ab%3A0xbee5bc15e92a8920!2z5rWE5b-D5a-6!5e0!3m2!1sja!2sjp!4v1661244830949!5m2!1sja!2sjp" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </section>

</section>

<!-- フッターのコンポーネント -->
<Footer class="mt-12"></Footer>

</div>
</template>


<script>

  import Footer from '../components/Footer.vue'

  export default {

    components: {
      Footer,
    },

    data() {
      return {

        // スライドショー用の画像配列。画像枚数を変更する時はこの配列を変更する。
        imgArray: [require('@/assets/slideImage_butsuzo.jpg'),require('@/assets/slideImage_hondou.jpg'),require('@/assets/slideImage_koke.jpg')],

        // スライドショーの処理で再起呼び出しを管理するフラグ
        recallFlag: true,

        // ブログのRssオブジェクト
        // 初期値を空配列にしている。v-forなので空配列でもエラーにならない。
        blogRss: [],

      }
    },

    computed: {
      
    },//computed終わりのカッコ。


    methods: {

      // スライドショー画像をボタンでスキップする時に使用。この関数でスムーズなスクロールを実現している。
      skipScroll() {
        window.scrollTo ({
          top: this.$refs.skipScrollTarget.offsetTop+50,
          // offsetTopの値を増やすとスクロール量が多いが縦幅は画面サイズによって違うのであまり大きい数字にはしない方がいい
          behavior: "smooth"
        });
      },


      // スライドショー関数の本体
      // 「slideNo = -1」はデフォルト引数
      topSlideShow(className, slideNo = -1) {

        // 他ページへ遷移してもスライドショーの再起呼び出しが発火してしまう。止めるためにフラグを元にif文で制御している。
        if(this.recallFlag === true) {

          // console.log('this.recallFlag === trueです。');
          let imgArray = document.querySelectorAll(className);
          if (slideNo >= 0) {
            //初回以外は現在のスライドを消す
            // console.log('opacity0の前に来た');
            imgArray[slideNo].style.opacity = 0;
          }
          slideNo++;
          if (slideNo >= imgArray.length) {
            //次のスライドがなければ最初のスライドへ戻る
            slideNo = 0;
          }
  
          // ここでopacity = 1になった画像だけが表示される
          imgArray[slideNo].style.opacity = 1;
  
          // スライドショー用関数を呼ぶ待ち時間
          let waitTime = 7000;
  
          // スライドショー用関数を再起呼び出し。アロー関数で書かないとエラーになる。
          setTimeout(() => {this.topSlideShow(className, slideNo);}, waitTime);

        } else {
          // console.log('画面遷移したのでスライドショーPGストップ')
        }

      },


      // ブログのRSSを取得
      async getBlogRss() {

        // 「rss to json」というサービスを利用。名前の通りrssをjsonで返してくれる。

        // 変数「baseURL」は「rss to json」サービスの基礎となるURL
        const baseURL = "https://api.rss2json.com/v1/api.json?rss_url=";

        // 変数「rssFeedURL」はrssフィードを取得したい各社ブログサービスのURL。
        // JUGEMブログ「住職日記」のRSS
        const rssFeedURL = "http://joushinji.jugem.jp/?mode=rss";

        // rss feed を取得(baseURL}と{rssFeedURLを連結させて取得し、JSONで返却される。しかし返却されるデータはJSON以外も含む。fetch()で取得するとこうなるものらしい。 )
        const response = await fetch(`${baseURL}${rssFeedURL}`);
        // console.log(response);

        // responseからjsonを取り出してオブジェクト形式へ変換する作業は.json()だけでOK。
        // ちなみに.json()はawaitがないとエラーになる
        const data = await response.json();

        // console.log(data);
        // console.log(data.items);

        // map関数で日付データを取得して表示が読みやすいようにフォーマット加工
        // この関数で出来る配列は参照型なので新たに変数を定義して代入する必要はない(例:const ○○のようなものへ代入不要)
        data.items.map( value => {

          // Safariでは「-」だとDate型変換でエラーになるため「/」へ置換すると日付が正しく表示される
          value.pubDate = value.pubDate.replace(/-/g,"/");
          // console.log(value.pubDate);
          
          // date型へ変換。ブログの時間なので変数名を「blogDate」にした
          // date型へ変換しなくても文字列変換でも可能だがこちらの方が日付フォーマットしやすいのでdate型へ変換した
          const blogDate = new Date(value.pubDate);
          // console.log(blogDate);

          // padStart()を使用し0で埋めている。例:「05月」
          const year = blogDate.getFullYear().toString().padStart(4, '0');
          const month = (blogDate.getMonth() + 1).toString().padStart(2, '0');
          const day = blogDate.getDate().toString().padStart(2, '0');

          // const hour = blogDate.getHours().toString()
          // const minute = blogDate.getMinutes().toString().padStart(2, '0');

          // 時刻をフォーマットする。見やすさのために「時」の前に半角スペースを2個入れている。
          // const formattedDateText = `${year}年${month}月${day}日  ${hour}時${minute}分`;
          // その後、時と分は不要になったので使用していない。
          const formattedDateText = `${year}年${month}月${day}日`;
          // console.log(formattedDateText);

          // 意外と忘れがちな代入。これをしないとせっかく加工したデータが反映されない。
          value.pubDate = formattedDateText

          // 最後はreturnで「value」を返却する。データ加工した箇所は「value.pubDate」だが返却するのは全体の「value」にする。
          return value

        });

        // rssの全てのデータは今回不要なので.itemsだけVue.jsのdataへ代入する。.itemsの中にブログのタイトルや日付データがある。
        // 最新5件だけ取得して代入。デフォルトでは10件取得出来るが5件だけあればいい。rssデータは新しいものが配列の先頭に来るので先頭から5件取得すれば最新5件が表示される。
        data.items = data.items.slice(0, 5);
        this.blogRss = data.items
      }


    }, //methods終わりのカッコ。消さないように。


    watch: {

    },//watch終わりのカッコ。


    created() {
      // console.log('Home.vueのcreatedが実行された。');

    },//created終わりのカッコ。


    mounted() {
      // console.log('Vue.jsのmountedが実行された。');
      // 自作スライドショー用関数をページが読み込んだ時に実行
      // 「slide-img」というCSSクラスを引数へ入れる。「.」はCSSクラスという意味。

      // スライドショーの画像が再読み込みする度に1枚目の画像だけopacity = 0が効いていない問題に対応するためsetTimeout()で関数発火の時間を遅らせている
      // 通常は必要ないがsafariブラウザではmounted()が正常動作しないことが分かった
      // 以下は正常に作動しているがsetTimeout()を使用しているのが気になる
      setTimeout(() => {this.topSlideShow('.slide-img');}, 10);

      // ブログのRSSを取得
      this.getBlogRss();

    },//mounted終わりのカッコ。


    beforeDestroy() {
      // スライドショーの処理の再起呼び出しを止める。
      // ちなみにトップページに戻って来た時はインスタンスがまた作られるため「this.recallFlag」をtrueにする処理は必要ない。
      this.recallFlag = false;
    },


  }// export defaultの終わりのカッコ。
</script>


<style scoped>

.img-top-area {
  /* background-color: #f76e6e; */

  /* スライドショー画像の左がギザギザしてしまう現象を修正するため、スライドショー画像の親であるこの場所でflex-endして右寄せした。 */
  display: flex;
  justify-content: flex-end;
}

/* flexにして矢印画像を縦方向の終わりへ配置している。その後の位置微調整は矢印画像のimgタグへ直接tailwindで書いている。 */
.btn-skip-tablet {
  /* 「display: flex」はtailwindを使用してウインドウ幅で制御している。独自CSSで付与するとスマホサイズで表示されてしまう。 */
  /* display: flex; */

  /* 「align-items」は上下(縦方向)の位置決めで、「justify-content」は左右(横方向)の位置決め */
  align-items: flex-end;

  /* 矢印の大きさ */
  width: 10vw;
  animation: skipArrowTablet 1.8s infinite;
}

/* スマホサイズのスキップボタン */
.btn-skip {
  /* 矢印の大きさ */
  width: 40px;
  /* 位置を上へズラしてスライドショー画像の上へ重ねる */
  transform: translate(0,-40px);
  z-index: 100;
  /* 背景色をつけてボタンを画像の上でも分かりやすくしている */
  background-color: #ffffff;
  border-radius: 20px;
  opacity: 0.6;
  animation: skipArrow 1.8s infinite;
}


.video-area {

  /* デザインがスライド画像要素はフルに表示されないのでここで横幅を調整している。 */
  width: 90%;
  /* width: 100%; */

  /* 画像の左上を角丸へ */
  /* border-radiusは長方形に％で指定すると楕円になり形が変になる。％以外で設定すること。 */
  border-radius: 80px 0 0 0;

  /* スライドショー画像要素の高さ。高さがないと表示されない。 */
  /* height: 100vh; */
  /* height: 200vh; */
  height: 75vh;

  overflow: hidden;

  /* ここの背景色もスマホ実機でページを見ると読み込みの際1度だけ表示されてしまうのでコメントアウトする。 */
  /* background-color: rgb(239, 239, 127); */
}


/* YouTube解説動画のクラス「video」と同じになるようにした */
.bg-movie {
  position: absolute;
  z-index: -1;

  /* object-fitを使用するとvideoのソースにある動画の縦横比のCSS設定が効くようになる */
  /* object-fit: fill; */
  object-fit: cover;
  /* object-fit: contain; */

  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  /* 背景画像を左右にアニメーションしても画像を見せるために横幅を100%以上にしている */
  width: 120%;
  height: 120%;

  min-width: 120%;
  min-height: 120%;

  /* ここの背景色がスマホ実機でページを見ると読み込みの際1度だけ表示されてしまうのでコメントアウトする。 */
  /* 背景色が見えると言うことはスライドショー画像の要素が表示用に計算されていないと思われる。なぜかスマホ実機だけなる症状 */
  /* たぶんVue.jsの一部条件だけで起きる問題だと予想される。完璧は解決方法は不明なので背景色をつけないことで対応することにした。 */
  /* background-color: rgb(123, 251, 189); */
  
}


/* 自作スライドショー用 */
.slide-img {
	position:absolute;
	opacity:0;
	/* transition:all 1s ease-in-out; */

  /* transition:allではなく単品で設定している。今後設定を追加するならtransition:allを使用する。*/
	transition: ease-in-out 1s;
	/* height:100%; */
  width: 150%;
}


/* スライドショー画像をズームさせる */
.bg-anime-zoom {
  animation:8s ease-in 1s infinite alternate forwards running zoomAnime;
}


/* ここで奥行を設定しないとtranslate3d()のZ軸が動作しない */
.bg-depth {
  /* 適当に3000pxにしているがこんなに要らないかも */
  perspective: 3000px;
}

/* 以下はスライドショー画像へのアニメ設定 */
/* X軸Y軸に50%を設定している理由は別のクラスでX軸Y軸50%にして中心に配置しているCSSを打ち消すため */

/* ズームするアニメ */
/* translate3d()の値は現在何となく設定している 今微調整の必要あり */
@keyframes zoomAnime {
  0%{
    /* transform: translate3d(-55%,-50%,200px); */
    /* スライドショー画像を中心へ配置するため。隙間を空けるデザインにしたため画像の中心点がズレたのでここで修正している。 */
    /* ここでtranslate3d()に数値を設定することでスライドショー画像の上方向飛び出しを防ぐ効果がある。 */
    /* transform: translate3d(calc(-48% - 1%/9),-50%,200px); */
    /* スライドショー画像の切り取り位置が近くになりすぎたため少し引くために値を変更した。カメラ位置を引くイメージ。本堂の写真上部が写らなくなったため。 */
    transform: translate3d(calc(-48% - 1%/9),-50%,-50px);
  }
  100%{
    /* transform: translate3d(-50%,-50%,250px); */
    /* transform: translate3d(-55%,-50%,350px); */
    /* 100%の時も同じで何か数値を設定してスライドショー画像の上方向飛び出しを防ぐ */
    /* transform: translate3d(calc(-48% - 1%/9),-50%,350px); */
    transform: translate3d(calc(-48% - 1%/9),-50%,100px);
  }
}


/* スライドショー画像スキップボタンへのアニメーション */
/* タブレットサイズ以上 */
@keyframes skipArrowTablet {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 25px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* スマホサイズ */
@keyframes skipArrow {
  0% {
    transform: translate(0, -40px);
  }
  50% {
    transform: translate(0, -55px);
  }
  100% {
    transform: translate(0, -40px);
  }
}


/* スマートフォン表示用のメディアクエリ */
/* tailwindだけでは設定出来ないものをここで自作する */
@media screen and (max-width:767px) {

  /* スマホ用表示ではスライドショー画像の横幅、角丸、隙間を無しにしている */
  .video-area {
    width: 100%;
    border-radius: 0 0 0 0;
    transform: translate(0,0);
  }

}

/* WQHDディスプレイでの解像度の違いから来るmarginの調整 */
@media (min-width: 2100px) { 

  .wqhd-margin-top {
    margin-top: 150px;
  }

 }

</style>
