<template>
<div id="app">

  <section class="top-0 left-0">
    <!-- 全体を「relative」で囲っている -->
    <div class="relative">

      <!-- スマホサイズの時だけページ上部に表示する「淨心寺」だけの文字 -->
      <!-- ロゴ文字画像に「absolute」を使用して囲ったdivには高さを設定している -->
      <div class="h-32 md:hidden">
        <img src="./assets/joushinji_logo_separate.png" alt="" class="w-36 logo-img">
      </div>


      <!-- 淨心寺ロゴとメニューは横並び -->
      <!-- pb-4でメニューの白色を伸ばしている。メニューとコンテンツの隙間を空けている。 -->
      <div class="flex pt-8 top-0 left-0 z-50 absolute bg-white pb-4">
        <div class="text-4xl text-gray-700 tracking-widest font-bold w-4/12 hidden md:block">
          <a>
            <img src="./assets/joushinji_logo.png" alt="" class="mx-auto w-8/12 cursor-pointer" v-on:click="jumpTop()">
          </a>
        </div>
        <!-- メニュー要素全体が入れ物のdiv要素の下に常に配置させるために「relative」と「bottom-0」の組み合わせにした。高さ要素もないといけないので追加した。 -->
        <div class="w-8/12 relative">
          <div class="absolute h-14 md:h-20 bottom-0 w-full right-0">
            <div class="hidden justify-end mr-16 font-semibold  md:flex">
              <!-- 「お知らせ」「アクセス」にはアンカーを設定した -->
              <div class="mr-16 text-gray-700"><router-link to="/">ホーム</router-link></div>
              <div class="mr-16 text-gray-700"><router-link to="/#informationBlock">お知らせ</router-link></div>
              <div class="text-gray-700"><router-link to="/#accessBlock">アクセス</router-link></div>
            </div>
            <div class="mt-3 md:mt-6">
              <NormalMenu class="z-40"></NormalMenu>
            </div>
          </div>
        </div>
      </div>

      <!-- メニューが配置してあるトップと「router-view」の隙間が画面サイズによって大きく差分が出ないように細かくブレークポイントを設定した -->
      <router-view class="z-0 absolute w-full top-28 md:top-32 lg:top-40 xl:top-44 2xl:top-48"></router-view>

    </div>
  </section>

</div>
</template>


<script>

import NormalMenu from './components/NormalMenu.vue'

export default {
  name: 'App',
  components: {
    NormalMenu,
  },

  methods: {
    // トップページへ遷移
    jumpTop() {

      // ページ遷移の問題はまだ完全解決していない。この場所にメニュージャンプ用のurlの初期化処理を書かないといけない。
      // もしやるならpropsdown

      // this.$router.push('/').catch(err => {console.log(`${err}同じページを開いた`)})

      // 同一ページのエラーメッセージを避けるための処理。処理自体は何も書いていない
      this.$router.push('/').catch( () => {} )
    }
  },


}
</script>


<style>
/* Googleフォント「Klee_One」を読み込み */
@import url('https://fonts.googleapis.com/css2?family=Klee+One&display=swap');

#app {
  /* Googleフォント「Klee_One」を使用 */
  font-family: 'Klee One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* スマホサイズだけトップに表示するロゴ画像文字。 */
.logo-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* z-index 40にしている理由はtailwindのz-indexの最大値が50なのでそれより下に配置したいため。もっと正確に設定するべきだろうが動くからOK */
  z-index: 40;
}
</style>
