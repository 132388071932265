import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Guide from '../views/Guide.vue'
import Jihou from '../views/Jihou.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide
  },
  {
    path: '/jihou',
    name: 'Jihou',
    component: Jihou
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
]


const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
          return savedPosition;
      }
      
      if (to.hash) {
          return {selector: to.hash}
      }

      return {x: 0, y: 0};
  },
  routes: routes
})

export default router
